import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
  FormLabel,
  Grid,
  Checkbox,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Draggable from "react-draggable";
import AddIcon from "@mui/icons-material/Add";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SuiButton from "components/SuiButton";
import colors from "assets/theme/base/colors";
import SuiInput from "components/SuiInput";
import { useGetTokenInfoMutation } from "app/features/api/AuthApi";
import SuiBox from "components/SuiBox";
import { CheckBox } from "@mui/icons-material";
import SuiTypography from "components/SuiTypography";
import { leadCollectionformdata } from "layouts/template/answer";
import HyperIcon from "Container/Icons/HyperIcon";
import EditIcon from "Container/Icons/EditIcon";

function DraggablePaperComponent(props) {
  return (
    <Draggable
      handle=".draggable-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      bounds="parent"
    >
      <Paper {...props} />
    </Draggable>
  );
}
const ResponsiveDialogWithTable = ({
  open,
  setOpenModal,
  handleClose,
  title,
  initialData,
  formik,
  currentRowIndex,
  rowData,
  handleSaveCalender,
  setSelectedCalender,
  selectedCalender,
  hyperLinkdata,
  hyperLinkDatas,
  answersData,
  leadCollectionHandler,
  leadCollectionData,
  loginType,
  handleOpenDialog,
  referencePara,
  setLeadFormData,
  leadFormData,
}) => {
  const questionName = rowData[currentRowIndex]?.questionName;
  const [rows, setRows] = useState(initialData);
  const [leadTitleError, setLeadTitleError] = useState("");
  const [errorMessages, setErrorMessages] = useState([]);
  const [ldParamValue, setLdParamValue] = useState([
    {
      id: 0,
      value: "",
    },
  ]);
  const {} = formik;
// debugger
  const [errors, setErrors] = useState([]);
  const [saveError, setSaveError] = useState("");
  const [hyperError, setHypererror] = useState("");
  const [hyperQuetion, setHyperQuetion] = useState("");
  const [texthyperError, settexthyperError] = useState("");
  const [getTokenInfo] = useGetTokenInfoMutation();
  const [tokenList, setTokenList] = useState([]);
  const [text, setText] = useState("");
  const [url, setUrl] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [paramValue, setParamValue] = useState([]);
  const [newOpenDialog, setNewOpenDialog] = useState(false);
  const [leadError, setLeadError] = useState("");
  const [newLeadData, setNewLeadData] = useState(null);
  const [newLeadDialog, setNewLeadDialog] = useState(false);
  const [currId, setCurrId] = useState();
  const [newTitle, setNewTitle] = useState();
  const [formData, setFormData] = useState([
    {
      questionName: "",
      answerType: "",
      name: "",
      isPushed: false,
      referenceParameterId: null,
      id: 0,
      formTitle: "",
    },
  ]);

  console.log("LeadCollectionformData", formData)

  const [isDisplayIcon, setIsDisplayIcon] = useState("");
  const [parmError, setParamError] = useState(false);
  const newRowRef = useRef(null);
  useEffect(() => {
    setLeadError("");
  }, [formData]);

  useEffect(() => {
    if (leadCollectionData[currentRowIndex]) {
      setFormTitle(leadCollectionData[currentRowIndex]?.title);
      
      if (leadCollectionData[currentRowIndex]?.data?.length === 0) {
        setFormData([{
            questionName: "",
            answerType: "",
            isPushed: false,
            name: "",
            referenceParameterId: "",
            id: "",
          },
        ]);
      } else {
        setFormData(
          leadCollectionData[currentRowIndex]?.data.map((item) => ({
            questionName: item?.questionName,
            isPushed: item?.isPushed,
            answerType: item?.answerType,
            name: item?.name ? item?.name : item?.cb_ReferenceParameter?.name,
            referenceParameterId: item?.cb_ReferenceParameter?.type === "custom" ? "custom" : item?.referenceParameterId, id: item?.id,
          }))
        );
      }

    }
  }, [currentRowIndex, newOpenDialog, leadCollectionData]);

  const tokenResponse = async () => {
    try {
      const response = await getTokenInfo();
      if (response?.data?.statusCode === 200) {
        setTokenList(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    // Find the hyperlink from hyperLinkDatas or rowData based on currentRowIndex
    const hyperLink = hyperLinkDatas?.find((item) => item.id === currentRowIndex)?.hyperlink;
    const rowDataHyperLink = rowData[currentRowIndex]?.hyperLink;
    const data = hyperLink || rowDataHyperLink;
  
    // Set the text if data is an array and contains textToBeLinked
    setText(
      Array.isArray(data) && data[0]?.textToBeLinked
        ? data[0].textToBeLinked
        : ""
    );
  
    // Set the URL if data is an array and contains a URL
    setUrl(
      Array.isArray(data) && data[0]?.url
        ? data[0].url
        : ""
    );
  }, [open, hyperLinkDatas, rowData, currentRowIndex]);
  
  // useEffect(() => {
    
  //   const hyperLink = hyperLinkDatas?.find((item) => item.id === currentRowIndex)?.hyperlink;
  //   const rowDataHyperLink = rowData?.find((item) => item.id === currentRowIndex)?.hyperlink;
  //   const data = hyperLink || rowDataHyperLink;
  //   // debugger
  //   console.log("currentRowIndex",currentRowIndex,hyperLink,rowData)
  //   setText(
  //     // (Array.isArray(hyperLinkDatas[currentRowIndex]?.hyperlink) &&
  //     //   hyperLinkDatas[currentRowIndex]?.hyperlink[0]?.textToBeLinked) ||
  //       (Array.isArray(data) && data[0]?.textToBeLinked) ||
  //       ""
  //   );

  //   setUrl(
  //     // (Array.isArray(hyperLinkDatas[currentRowIndex]?.hyperlink) &&
  //     //   hyperLinkDatas[currentRowIndex]?.hyperlink[0]?.url) ||
  //       (Array.isArray(data) && data[0]?.url) ||
  //       ""
  //   );
  // }, [open]);

  useEffect(() => {
    if (title === "Calender") {
      tokenResponse();
    }
  }, [title]);

  useEffect(() => {
    if (open) {
      setRows(initialData);
    }
  }, [open, initialData]);

  useEffect(() => {
    if (newRowRef.current) {
      newRowRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [rows.length, formData.length, ldParamValue.length]);

  const dialogTitle =
    title === "Selectable_Option"
      ? "Selectable Options"
      : "Choose Answer(Drop Down)";

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const validateQuestionToHit = (value, index) => {
    const maxQuestionToHit = rowData.length;
    const questionNumber = rowData[currentRowIndex].questionId;
    let error = "";

    if (!value) {
      error = "";
    } else if (value > maxQuestionToHit) {
      error = " Jump to value count exceeds the number of  question.";
    } else if (questionNumber === parseInt(value)) {
      error = "Jump to value must differ from question number.";
    } else if (value < questionNumber) {
      error = "Jump to value cannot be lesser than the question number.";
    }

    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = { ...newErrors[index], questionToHit: error };
      return newErrors;
    });
  };

  const validateValue = (value, index) => {
    let error = "";

    if (!value) {
      error = "Value is required.";
    } else if (value.length < 2) {
      error = "Value must be at least 2 characters long.";
    }

    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = { ...newErrors[index], value: error };
      return newErrors;
    });
  };

  const handleAddRow = () => {
    setRows([...rows, { value: "", questionToHit: "" }]);
    setErrors([...errors, { value: "", questionToHit: "" }]);
  };

  const handleRemoveRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);

    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors);
  };

  const handleInputChange = (index, field, value) => {
    if (field === "questionToHit") {
      validateQuestionToHit(value, index);
    } else if (field === "value") {
      validateValue(value, index);
    }

    const newRows = rows?.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );
    setRows(newRows);

    const questionToHitValues = newRows?.map((row) => row?.questionToHit);
    // eslint-disable-next-line no-undef
    const allFilledOrAllEmpty =
      questionToHitValues?.every((val) => val === "") ||
      questionToHitValues?.every((val) => val !== "");
    if (allFilledOrAllEmpty) {
      setSaveError("");
    } else if (field === "questionToHit") {
      setSaveError("All Jump to fields must be filled or all must be empty.");
    }
  };

  const handleTextInputChange = (field, value) => {
    if (field === "text") {
      setText(value);
    } else if (field === "url") {
      setUrl(value);
    }
  };

  const addCollectionSave = () => {
    if (!formTitle.trim()) {
      setLeadError("Form title is required.");
      return;
    }
    if (formData?.length >= 2) {
      const invalidFields = formData.filter(
        (x) => !x.questionName.trim() || !x.answerType.trim() ||  x.answerType === "Select Answer"
      );

      if (invalidFields.length > 0) {
        setLeadError(
          "Please fill all the question and answer !."
        );
        return;
      }

      const payload = {
        title: formTitle,
        data: formData.map((x, i) => {
          return { ...x, paramValue: rows };
        }),
      };

      leadCollectionHandler(payload);
      setFormTitle("");
      setLeadError("");
      setFormData([
        {
          questionName: "",
          answerType: "",
          isPushed: false,
          referenceParameterId: null,
          formTitle: "",
        },
      ]);
      setLeadError("");
      setOpenModal(false);
    } else {
      setLeadError("Minimum 2 fild requird");
    }
  };

  const handleSave = () => {
    if (title === "Link") {
      if (questionName === "") {
        // settexthyperError("vvv");
        setHyperQuetion("Please enter a question first.");
      }else{
        setHyperQuetion("")
      }
      // Trim the URL to avoid issues caused by extra spaces
      const trimmedUrl = url.trim();
      const trimmedText = text.trim(); // Ensure text is also trimmed

      // Check if text input is empty
      if (!trimmedText) {
        settexthyperError("Text input cannot be empty.");
        return;
      }
      // Refined URL validation pattern
      const urlPattern = /^(https?:\/\/)[a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})(\/[^\s]*)?$/;

      // Test the trimmed URL
      if (!urlPattern.test(trimmedUrl)) {
        setHypererror(
          "Please enter a valid link starting with http:// or https:// without invalid characters."
        );
        return;
      } else {
        setHypererror("");
      }

      if (hyperQuetion === "" && texthyperError === "" && saveError === "") {
        setOpenModal(false);
      }
      // setOpenModal(false);

      const hyperlink = [
        {
          url: trimmedUrl,
          textToBeLinked: text,
        },
      ];

      let obj = {
        id: currentRowIndex,
        hyperlink,
      };

      hyperLinkdata((priv) => [
        ...priv.filter((item) => item.id !== currentRowIndex),
        obj,
      ]);

      // hyperLinkdata((prev) => 
      //   prev.map((item, index) => 
      //     index === currentRowIndex ? obj : item
      //   )
      // );
      
      // setText("");
      // setUrl("");

      setSaveError(""); // Clear any previous errors

      // Ensure the input matches the required questionName
      const requiredQuestionName = rowData[currentRowIndex]?.questionName || "";

      // Split questionName into individual words
      const questionWords = requiredQuestionName.split(" ");

      // Check if text matches any word in the questionName
      const isMatch = questionWords.some((word) =>
        trimmedText.toLowerCase().includes(word.toLowerCase())
      );

      if (!isMatch) {
        setSaveError(`The input must match at least one word in the question.`);
        return;
      }
    }

    // Continue with other validations
    // const hasErrors = rows.some(
    //   (error) => error?.value === "" && error?.questionToHit === ""
    // );
    // console.log(hasErrors);
    // if (hasErrors) {
    //   setSaveError("Kindly fill all the field values");
    //   return;
    // }

    const nameArray = rows.map((row) => row.value);
    const allValueGreater = nameArray?.some((val) => val.length < 2);
    if (allValueGreater) return;

    const questionToHitValues = rows.map((row) => row.questionToHit);
    const allFilledOrAllEmpty =
      questionToHitValues.every((val) => val === "") ||
      questionToHitValues.every((val) => val !== "");
    const everyErrorEmpty = errors?.every(
      (val) => val?.questionToHit === "" || !val?.questionToHit
    );
    if (!everyErrorEmpty) return;
    if (!allFilledOrAllEmpty) {
      setSaveError("All Jump to fields must be filled or all must be empty.");
      return;
    }

    const nameValue = rows.map((row) => row.value);
    const allNameFilledOrAllEmpty = nameValue.some((val) => val === "");
    if (allNameFilledOrAllEmpty) {
      setSaveError("Kindly fill the name value");
      return;
    }

    setSaveError("");
    const formattedData = rows.map((row, index) => ({
      id: parseInt(index + 1),
      value: row.value,
      questionToHit: row.questionToHit,
    }));
    // setFieldValue("data", updatedRows);

    handleClose(formattedData);
  };

  const maxRows =
    title === "Selectable_Option"
      ? 6
      : title === "Searchable_Dropdown"
      ? 10
      : Infinity;
  const isAddDisabled = rows.length >= maxRows;

  const leadMaxRows =
    newTitle === "Selectable_Option"
      ? 6
      : newTitle === "Searchable_Dropdown"
      ? 10
      : Infinity;
  const isLeadAddDisabled = ldParamValue.length >= leadMaxRows;
  const handleSubmitCalender = (value) => {
    if (!value) {
      setSaveError("Kindly select calendar");
    } else {
      handleSaveCalender(value);
    }
  };

  const handleOpenDialogBox = (row, index) => {
    setNewTitle(row.answerType);
    let isData = [...leadFormData];
    let setUpdatedVal = isData?.filter((item, i) => item?.currId == row?.id);
    if (setUpdatedVal?.length > 0) {
      setLdParamValue(setUpdatedVal[0]?.ldParamValue);
    } else {
      setLdParamValue([
        {
          id: 0,
          value: "",
        },
      ]);
    }
    setCurrId(row.id);
    setNewLeadDialog(true);
  };

  const handleSaveLeadFormValue = () => {
    setLeadFormData((prev) => {
      const updatedData = prev.map(
        (res) =>
          res.currId === currId
            ? { ...res, ldParamValue } // Update ldParamValue for the matching currId
            : res // Keep the other items unchanged
      );

      // If currId doesn't exist in the array, add a new object
      const isCurrIdExist = prev.some((res) => res.currId === currId);
      return isCurrIdExist
        ? updatedData
        : [...updatedData, { currId, currentRowIndex, ldParamValue }];
    });

    setCurrId();
  };

  return (
    <div>
      <Dialog
        disableScrollLock
        open={open}
        onClose={() => {
          handleClose(null);
          setErrors([]);
          setSaveError("");
        }}
        fullScreen={fullScreen}
        fullWidth
        maxWidth={title === "Lead_Collection_Form" ? "lg" : "md"}
        PaperComponent={DraggablePaperComponent}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            padding: "16px",
            // maxHeight: 375
          },
        }}
      >
        <SuiBox>
          {title === "Selectable_Option" || title === "Searchable_Dropdown" ? (
            <>
              <IconButton
                className="close-btn"
                onClick={() => {
                  handleClose(null);
                  setErrors([]);
                  setSaveError("");
                  if (newOpenDialog) {
                    handleOpenDialog(0, "Lead_Collection_Form");
                  }
                }}
                color="light"
                sx={{ bgcolor: `${colors.text.main} !important` }}
              >
                <CloseRoundedIcon />
              </IconButton>
              <SuiBox className="draggable-title" style={{ cursor: "pointer" }}>
                <DragIndicatorIcon />
              </SuiBox>
              <DialogTitle
                className="draggable-title"
                fontWeight="700"
                style={{ cursor: "pointer" }}
              >
                {dialogTitle}
              </DialogTitle>
              <DialogContent
                className="draggable-title"
                sx={{
                  maxHeight: 230,
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                    borderRadius: "8px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#555",
                  },
                }}
              >
                <TableContainer
                  component={Paper}
                  elevation={0}
                  sx={{ boxShadow: "none", borderRadius: 0 }}
                >
                  <Table sx={{ borderCollapse: "collapse", borderTop: 0 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ borderBottom: "none" }}>
                          NAME
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }}>
                          JUMP To
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => {
                        return (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell sx={{ borderBottom: "none" }}>
                                <SuiInput
                                  fullWidth
                                  inputProps={{ maxLength: 100 }} // Use inputProps if supported by the custom component
                                  value={row.value}
                                  onChange={(e) => {
                                    handleInputChange(
                                      index,
                                      "value",
                                      e.target.value
                                    );
                                  }}
                                  placeholder={`Option ${index + 1}`}
                                />
                                {/* {errors[index] && errors[index].value && (
                              <Box mt={1} color="red" fontSize="12px">
                                {errors[index].value}
                              </Box>
                            )} */}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  minWidth: "200px",
                                }}
                              >
                                <TextField
                                  type="number"
                                  size="small"
                                  value={row.questionToHit}
                                  onChange={(e) => {
                                    handleInputChange(
                                      index,
                                      "questionToHit",
                                      e.target.value
                                    );
                                  }}
                                  inputProps={{
                                    onWheel: (event) => event?.target?.blur(),
                                    sx: {
                                      width: "50px",
                                      height: "50px",
                                      textAlign: "center",
                                      maxWidth: "50px",
                                      border: "1px solid #E0E0E0",
                                      borderRadius: "4px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    },
                                    onKeyDown: (e) => {
                                      if (
                                        [
                                          "e",
                                          "E",
                                          "+",
                                          "-",
                                          ".",
                                          "ArrowUp",
                                          "ArrowDown",
                                        ].includes(e.key)
                                      ) {
                                        e.preventDefault();
                                      }
                                    },
                                  }}
                                />
                                {/* {errors[index] && errors[index].questionToHit && (
                              <Box mt={1} color="red" fontSize="12px" textAlign="center">
                                {errors[index].questionToHit}
                              </Box>
                            )} */}
                              </TableCell>
                              <TableCell sx={{ borderBottom: "none" }}>
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  {rows.length !== 1 && (
                                    <IconButton
                                      onClick={() => {
                                        handleRemoveRow(index);
                                        setErrors([]);
                                        setSaveError("");
                                      }}
                                      disabled={rows.length === 1}
                                      sx={{
                                        color: "red",
                                        "&:hover": {
                                          backgroundColor: "transparent",
                                        },
                                      }}
                                    >
                                      <RemoveIcon
                                        sx={{
                                          background: "#FF1733",
                                          color: "#FFFFFF",
                                          padding: "2px",
                                          borderRadius: "5px",
                                        }}
                                      />
                                    </IconButton>
                                  )}
                                  {index === rows.length - 1 &&
                                    !isAddDisabled && (
                                      <IconButton
                                        onClick={handleAddRow}
                                        sx={{
                                          color: "blue",
                                          "&:hover": {
                                            backgroundColor: "transparent",
                                          },
                                        }}
                                        disabled={isAddDisabled}
                                      >
                                        <AddIcon
                                          sx={{
                                            background: colors.primary.main,
                                            color: "#FFFFFF",
                                            padding: "2px",
                                            borderRadius: "5px",
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                </Box>
                              </TableCell>
                            </TableRow>
                            {/* Adding an empty row for error display */}
                            {(errors[index]?.value ||
                              errors[index]?.questionToHit) && (
                              <TableRow>
                                <TableCell
                                  colSpan={1}
                                  sx={{ borderBottom: "none" }}
                                >
                                  <Box color="red" fontSize="12px">
                                    {errors[index].value}
                                  </Box>
                                </TableCell>
                                <TableCell
                                  colSpan={1}
                                  sx={{ borderBottom: "none" }}
                                >
                                  <Box color="red" fontSize="12px">
                                    {errors[index]?.questionToHit}
                                  </Box>
                                </TableCell>
                                {/* <TableCell></TableCell> */}
                              </TableRow>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContent>
              {saveError && (
                <Box mt={2} mb={2} sx={{ textAlign: "center" }}>
                  <h6
                    style={{
                      fontSize: ".9em",
                      color: "#FC3C3C",
                      fontWeight: 400,
                      transition: ".2s all",
                    }}
                  >
                    {saveError}
                  </h6>
                </Box>
              )}
              <DialogActions
                sx={{
                  justifyContent: "left",
                  marginLeft: "15px",
                  cursor: "pointer",
                }}
                className="draggable-title"
              >
                <SuiButton
                  onClick={() => {
                    handleClose(null);
                    setErrors([]);
                    setSaveError("");
                  }}
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ maxWidth: "94px !important" }}
                >
                  Cancel
                </SuiButton>
                <SuiButton
                  onClick={() => {
                    handleSave();
                  }}
                  size="small"
                  variant="contained"
                  buttonColor="info"
                  sx={{ border: "1px solid #1264e2" }}
                >
                  Add
                </SuiButton>
              </DialogActions>
            </>
          ) : title === "Lead_Collection_Form" ? (
            <>
              <>
                <IconButton
                  className="close-btn"
                  onClick={() => {
                    handleSaveCalender(null);
                    setSaveError("");
                  }}
                  color="light"
                  sx={{ bgcolor: `${colors.text.main} !important` }}
                >
                  <CloseRoundedIcon />
                </IconButton>
                <SuiBox
                  className="draggable-title"
                  style={{ cursor: "pointer" }}
                >
                  <DragIndicatorIcon />
                </SuiBox>
                <DialogTitle fontWeight="700" className="draggable-title">
                  Lead Collection Form
                </DialogTitle>
                <DialogContent
                  className="draggable-title"
                  sx={{
                    maxHeight: 300,
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#f1f1f1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#888",
                      borderRadius: "8px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "#555",
                    },
                  }}
                >
                  <Grid item xs={12} lg={6}>
                    <FormLabel htmlFor="t_name" required>
                      Form Title
                    </FormLabel>
                    <SuiInput
                      inputSettungs={{ autoComplete: "off", required: true }}
                      type="text"
                      placeholder="Enter Form Title"
                      id="t_name"
                      name="t_name"
                      value={formTitle}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormTitle(value); // Always update the input value

                        // Show error only if length exceeds 30
                        if (value.length > 30) {
                          setLeadTitleError(
                            "The maximum character limit is 30."
                          );
                        } else {
                          setLeadTitleError(""); // Clear the error
                        }
                      }}
                    />
                    {leadTitleError && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {leadTitleError}
                      </span>
                    )}
                    {/* <ErrorMessageComponent
                      touched={formik.touched.t_name}
                      error={formik.errors.t_name}
                    /> */}
                  </Grid>

                  <TableContainer
                    component={Paper}
                    elevation={0}
                    sx={{
                      boxShadow: "none",
                      border: "none",
                      marginTop: "10px",
                    }}
                  >
                    <Table
                      aria-label="template form table"
                      // sx={{ minWidth: 600 }}
                    >
                      <TableHead
                        sx={{
                          whiteSpace: "nowrap",
                          borderTop: "none !important",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{
                              borderTop: "none !important",
                              borderBottom: "none !important",
                            }}
                          >
                            Data push
                          </TableCell>
                          <TableCell
                            sx={{
                              borderTop: "none !important",
                              borderBottom: "none !important",
                            }}
                          >
                            Parameters
                          </TableCell>
                          <TableCell
                            sx={{
                              borderTop: "none !important",
                              borderBottom: "none !important",
                            }}
                          >
                            Question
                          </TableCell>
                          <TableCell
                            sx={{
                              borderTop: "none !important",
                              borderBottom: "none !important",
                            }}
                          >
                            Answer
                          </TableCell>
                          <TableCell
                            sx={{
                              borderTop: "none !important",
                              borderBottom: "none !important",
                            }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {formData.length > 0 &&
                          formData?.map((row, index, arr) => {
                            return (
                              <React.Fragment key={index}>
                                <TableRow
                                  ref={
                                    index === formData.length - 1
                                      ? newRowRef
                                      : null
                                  }
                                >
                                  <TableCell
                                    sx={{
                                      padding: "16px",
                                      textAlign: "left",
                                      borderBottom: "none",
                                    }}
                                  >
                                    <Checkbox
                                      color="secondary"
                                      checked={row.isPushed}
                                      style={{ borderRadius: "0 !important" }}
                                      sx={{
                                        "& svg": {
                                          borderRadius: "1px !important",
                                        },
                                        "& .MuiIconButton-root": {
                                          borderRadius: "1px !important",
                                        },
                                        "&.MuiCheckbox-root": {
                                          borderRadius: "1px !important",
                                        },
                                        "& .css-1me0qvy-MuiButtonBase-root-MuiCheckbox-root":
                                          {
                                            borderRadius: "1px !important",
                                          },
                                      }}
                                      onChange={(e) =>
                                        setFormData((priv) => {
                                          let data = [...priv];
                                          data[index].isPushed =
                                            e.target.checked;
                                          return data;
                                        })
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      // minWidth: "200px",
                                    }}
                                  >
                                    <Select
                                      name={`referenceParameterId${index}`}
                                      fullWidth
                                      value={
                                        row.referenceParameterId === null ||
                                        row.referenceParameterId === ""
                                          ? "Select Parameter"
                                          : row.referenceParameterId ===
                                            "custom"
                                          ? "custom"
                                          : row.referenceParameterId
                                      }
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          borderRadius: "2px !important",
                                          fontSize: "small !important",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          borderRadius: "2px !important",
                                          fontSize: "small !important",
                                        },
                                        "& .MuiSelect-select": {
                                          borderRadius: "2px !important",
                                          fontSize: "small !important",
                                        },
                                        "& .MuiSelect-icon": {
                                          borderRadius: "2px !important",
                                          fontSize: "small !important",
                                        },
                                        "& .css-mlog3m-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                                          {
                                            borderRadius: "2px !important",
                                            fontSize: "small !important",
                                          },
                                      }}
                                      onChange={(e) => {
                                        const value = e.target.value;

                                        setFormData((prev) => {
                                          console.log("e.target.value:", value);

                                          // Create a copy of the previous state
                                          let data = [...prev];

                                          // Update the specific index
                                          data[index].referenceParameterId =
                                            value === "Select Parameter"
                                              ? null
                                              : value;
                                          data[index].name =
                                            value === "custom"
                                              ? data[index].name
                                              : "";

                                          return data;
                                        });
                                      }}

                                      // displayEmpty
                                    >
                                      <MenuItem value="Select Parameter">
                                        Select Parameter
                                      </MenuItem>
                                      {referencePara?.map((item) =>
                                        item.name === "custom" ? null : (
                                          <MenuItem
                                            key={item.id}
                                            value={
                                              item.name === "custom"
                                                ? "custom"
                                                : item.id
                                            }
                                          >
                                            {item.name}
                                          </MenuItem>
                                        )
                                      )}
                                      <MenuItem value="custom">custom</MenuItem>
                                    </Select>
                                    {row.referenceParameterId === "custom" && (
                                      <SuiInput
                                        placeholder="Enter custom reference parameter"
                                        value={row?.name}
                                        onChange={(e) =>
                                          setFormData((priv) => {
                                            let data = [...priv];
                                            data[index].name = e.target.value;
                                            return data;
                                          })
                                        }
                                        fullWidth
                                        // sx={{ marginTop: "8px" }}
                                      />
                                    )}
                                    {/* {errors[index] && errors[index].questionToHit && (
                              <Box mt={1} color="red" fontSize="12px" textAlign="center">
                                {errors[index].questionToHit}
                              </Box>
                            )} */}
                                  </TableCell>
                                  <TableCell sx={{ borderBottom: "none" }}>
                                    <SuiInput
                                      sx={{ width: "220px" }}
                                      fullWidth
                                      inputProps={{ maxLength: 100 }} // Use inputProps if supported by the custom component
                                      value={row.questionName}
                                      onChange={(e) =>
                                        setFormData((priv) => {
                                          let data = [...priv];
                                          data[index].questionName =
                                            e.target.value;
                                          return data;
                                        })
                                      }
                                      // placeholder={`Option ${index + 1}`}
                                      placeholder="Questions"
                                    />
                                  </TableCell>
                                  <TableCell sx={{ borderBottom: "none" }}>
                                    <Select
                                      value={row.answerType || "Select Answer"}
                                      fullWidth
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          borderRadius: "2px !important",
                                          fontSize: "small !important",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          borderRadius: "2px !important",
                                          fontSize: "small !important",
                                        },
                                        "& .MuiSelect-select": {
                                          borderRadius: "2px !important",
                                          fontSize: "small !important",
                                        },
                                        "& .MuiSelect-icon": {
                                          borderRadius: "2px !important",
                                          fontSize: "small !important",
                                        },
                                        "& .css-mlog3m-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                                          {
                                            borderRadius: "2px !important",
                                            fontSize: "small !important",
                                          },
                                        width: "160px !important",
                                      }}
                                      onChange={(e) => {
                                        setFormData((priv) => {
                                          let data = [...priv];
                                          data[index].answerType =
                                            e.target.value;
                                          return data;
                                        });

                                        setLeadFormData((priv) => {
                                          let filterd = [...priv];
                                          let fRow = filterd.filter(
                                            (res) => res.currId !== row.id
                                          );
                                          return fRow;
                                        });
                                      }}
                                    >
                                      <MenuItem value="Select Answer">
                                        Select Answer Type
                                      </MenuItem>
                                      {leadCollectionformdata?.map((item) => (
                                        <MenuItem
                                          key={item.id}
                                          value={item.value}
                                          disabled={formData.length === index}
                                        >
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </TableCell>
                                  <TableCell sx={{ borderBottom: "none" }}>
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      {loginType === "tenant" ||
                                      loginType === "admin"
                                        ? [
                                            "Selectable_Option",
                                            "Searchable_Dropdown",
                                          ].includes(row.answerType) && (
                                            <IconButton
                                              color="secondary"
                                              onClick={() => {
                                                handleOpenDialogBox(row, index);
                                              }}
                                            >
                                              <EditIcon />
                                            </IconButton>
                                          )
                                        : [
                                            "Selectable_Option",
                                            "Searchable_Dropdown",
                                          ].includes(row.answerType) && (
                                            <IconButton
                                              color="secondary"
                                              onClick={() => {
                                                handleOpenDialogBox(row, index);
                                              }}
                                            >
                                              <EditIcon />
                                            </IconButton>
                                          )}

                                      {formData.length !== 1 && (
                                        <IconButton
                                          onClick={() => {
                                            setFormData((priv) => {
                                              let formD = [...priv];
                                              let fRow = formD.filter(
                                                (v) => v.id !== row?.id
                                              );
                                              return fRow;
                                            });
                                            setLeadFormData((priv) => {
                                              let formD = [...priv];
                                              let fRow = formD.filter(
                                                (v) => v.currId !== row?.id
                                              );
                                              return fRow;
                                            });
                                            // setLdParamValue()
                                          }}
                                          disabled={formData.length === 1}
                                          sx={{
                                            color: "red",
                                            "&:hover": {
                                              backgroundColor: "transparent",
                                            },
                                          }}
                                        >
                                          <RemoveIcon
                                            sx={{
                                              background: "#FF1733",
                                              color: "#FFFFFF",
                                              padding: "2px",
                                              borderRadius: "5px",
                                            }}
                                          />
                                        </IconButton>
                                      )}
                                      {index === formData.length - 1 && (
                                        <IconButton
                                          onClick={() => {
                                            setFormData((priv) => [
                                              ...priv,
                                              {
                                                questionName: "",
                                                answerType: "",
                                                isPushed: false,
                                                referenceParameterId: null,
                                                id: index + 1,
                                              },
                                            ]);
                                          }}
                                          sx={{
                                            color: "blue",
                                            "&:hover": {
                                              backgroundColor: "transparent",
                                            },
                                          }}
                                          disabled={!formData.length >= maxRows}
                                        >
                                          <AddIcon
                                            sx={{
                                              background: colors.primary.main,
                                              color: "#FFFFFF",
                                              padding: "2px",
                                              borderRadius: "5px",
                                            }}
                                          />
                                        </IconButton>
                                      )}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                {/* Adding an empty row for error display */}
                                {(errors[index]?.value ||
                                  errors[index]?.questionToHit) && (
                                  <TableRow>
                                    <TableCell
                                      colSpan={1}
                                      sx={{ borderBottom: "none" }}
                                    >
                                      <Box color="red" fontSize="12px">
                                        {errors[index].value}
                                      </Box>
                                    </TableCell>
                                    <TableCell
                                      colSpan={1}
                                      sx={{ borderBottom: "none" }}
                                    >
                                      <Box color="red" fontSize="12px">
                                        {errors[index]?.questionToHit}
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </React.Fragment>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DialogContent>
                {leadError && (
                  <span
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      marginLeft:"24px",
                      fontSize:"0.9em",
                      color:"rgb(252, 60, 60)"
                    }}
                  >
                    {leadError}
                  </span>
                )}
                <DialogActions
                  className="draggable-title"
                  sx={{ justifyContent: "left", marginLeft: "15px",marginTop:"13px" }}
                >
                  <SuiButton
                    size="small"
                    variant="outlined"
                    buttonColor="dark"
                    sx={{ px: "1rem", py: "0.4rem" }}
                    onClick={() => {
                      handleClose(null);
                    }}
                  >
                    Cancel
                  </SuiButton>
                  <SuiButton
                    size="small"
                    variant="contained"
                    buttonColor="info"
                    sx={{ px: "1rem", py: "0.4rem" }}
                    // onClick={addCollectionSave}
                    onClick={() => {
                      addCollectionSave();
                      setNewOpenDialog(false);
                    }}
                  >
                    Add
                  </SuiButton>
                </DialogActions>
              </>
            </>
          ) : title === "Link" ? (
            <>
              <>
                <IconButton
                  className="close-btn"
                  onClick={() => {
                    handleSaveCalender(null);
                    setSaveError("");
                    setHyperQuetion("");
                    settexthyperError("")
                    setHypererror("")
                  }}
                  color="light"
                  sx={{ bgcolor: `${colors.text.main} !important` }}
                >
                  <CloseRoundedIcon />
                </IconButton>
                <DialogTitle fontWeight="700">Set-Up Hyper Link</DialogTitle>
                <DialogContent
                  sx={{
                    maxHeight: 300,
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#f1f1f1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#888",
                      borderRadius: "8px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "#555",
                    },
                  }}
                >
                  <Grid container>
                    <Grid item md={4}>
                      <SuiTypography
                        sx={{ fontSize: "16px", fontWeight: "600" }}
                      >
                        Question
                      </SuiTypography>
                      <SuiTypography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "20px",
                        }}
                      >
                        Text to be Linked
                      </SuiTypography>
                      <SuiTypography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "20px",
                        }}
                      >
                        URL
                      </SuiTypography>
                    </Grid>
                    <Grid item md={8}>
                      {hyperQuetion && (
                        <div
                          style={{
                            color: "red",
                            marginTop: "5px",
                            fontSize: "14px",
                          }}
                        >
                          {hyperQuetion}
                        </div>
                      )}
                      <SuiTypography
                        sx={{ fontSize: "16px", fontWeight: "600" }}
                      >
                        {title === "Link"
                          ? rowData[currentRowIndex]?.questionName
                          : ""}
                      </SuiTypography>
                      <SuiInput
                        sx={{ marginTop: "10px" }}
                        inputSettings={{ autoComplete: "off", required: true }}
                        type="text"
                        placeholder="Enter Form Title"
                        id="text"
                        name="text"
                        value={text}
                        onChange={(e) => {
                          const inputValue = e.target.value.trim(); // Input value
                          const currentQuestionName =
                            rowData[currentRowIndex]?.questionName || ""; // Get current questionName

                          // Split both inputValue and questionName into words
                          const inputWords = inputValue
                            .toLowerCase()
                            .split(" ");
                          const questionWords = currentQuestionName
                            .toLowerCase()
                            .split(" ");
                        
                          // Check if all words in inputValue exist in questionWords
                          const isValid = inputWords.every((word) =>
                            questionWords.includes(word)
                          );

                          // Show error if any word is outside questionWords
                          if (!isValid) {
                            settexthyperError(
                              "No matching word in the question."
                            );
                          } else {
                            settexthyperError(""); // Clear error if input is valid
                          }
                        
                          // Update input value
                          handleTextInputChange("text", e.target.value);
                        }}
                        
                          
                      />

                      {texthyperError && (
                        <div
                          style={{
                            color: "red",
                            marginTop: "5px",
                            fontSize: "14px",
                          }}
                        >
                          {texthyperError}
                        </div>
                      )}

                      <SuiInput
                        sx={{ marginTop: "10px" }}
                        inputSettungs={{ autoComplete: "off", required: true }}
                        type="text"
                        placeholder="Enter URL"
                        id="url"
                        name="url"
                        value={url}
                        onChange={(e) =>
                          handleTextInputChange("url", e.target.value)
                        }
                      />
                      {hyperError && (
                        <div
                          style={{
                            color: "red",
                            marginTop: "5px",
                            fontSize: "14px",
                          }}
                        >
                          {hyperError}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </DialogContent>

                <DialogActions
                  sx={{ justifyContent: "left", marginLeft: "15px" }}
                >
                  <SuiButton
                   onClick={() => {
                    handleClose(null);
                    setHyperQuetion("");
                    settexthyperError("")
                    setHypererror("")
                  }}
                  
                    size="small"
                    variant="outlined"
                    buttonColor="dark"
                    sx={{ px: "1rem", py: "0.4rem" }}
                  >
                    Cancel
                  </SuiButton>
                  <SuiButton
                    onClick={handleSave}
                    size="small"
                    variant="contained"
                    buttonColor="info"
                    sx={{ px: "1rem", py: "0.4rem" }}
                  >
                    Add
                  </SuiButton>
                </DialogActions>
              </>
            </>
          ) : title === "Calender" ? (
            <>
              <IconButton
                className="close-btn"
                onClick={() => {
                  handleSaveCalender(null);
                  setSaveError("");
                }}
                color="light"
                sx={{ bgcolor: `${colors.text.main} !important` }}
              >
                <CloseRoundedIcon />
              </IconButton>
              <DialogTitle fontWeight="700">Select Calendar</DialogTitle>
              <DialogContent
                sx={{
                  maxHeight: 300,
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                    borderRadius: "8px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#555",
                  },
                }}
              >
                <Select
                  label="Select Calendar"
                  fullWidth
                  displayEmpty
                  value={selectedCalender}
                  onChange={(e) => {
                    setSaveError("");
                    setSelectedCalender(e.target.value);
                  }}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select Calendar</em>;
                    }
                    const item = tokenList.find(
                      (item) => item.id === selectedCalender
                    );
                    return item ? item.name : "";
                  }}
                >
                  <MenuItem value="" disabled>
                    <em>Select Calendar</em>
                  </MenuItem>
                  {tokenList?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </DialogContent>

              {saveError && (
                <Box mt={2} mb={2} sx={{ textAlign: "center" }}>
                  <h6
                    style={{
                      fontSize: ".9em",
                      color: "#FC3C3C",
                      fontWeight: 400,
                      transition: ".2s all",
                    }}
                  >
                    {saveError}
                  </h6>
                </Box>
              )}
              <DialogActions
                sx={{ justifyContent: "left", marginLeft: "15px" }}
              >
                <SuiButton
                  onClick={() => {
                    handleSaveCalender(null);
                    setSaveError("");
                  }}
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                >
                  Cancel
                </SuiButton>
                <SuiButton
                  onClick={() => handleSubmitCalender(selectedCalender)}
                  size="small"
                  variant="contained"
                  buttonColor="info"
                  sx={{ px: "1rem", py: "0.4rem" }}
                >
                  Add
                </SuiButton>
              </DialogActions>
            </>
          ) : (
            <>
              <IconButton
                className="close-btn"
                onClick={() => {
                  handleClose(null);
                  setErrors([]);
                  setSaveError("");
                }}
                color="light"
                sx={{ bgcolor: `${colors.text.main} !important` }}
              >
                <CloseRoundedIcon />
              </IconButton>
              <SuiBox className="draggable-title" style={{ cursor: "pointer" }}>
                <DragIndicatorIcon />
              </SuiBox>
              <DialogTitle
                className="draggable-title"
                fontWeight="700"
                style={{ cursor: "pointer" }}
              >
                {dialogTitle}
              </DialogTitle>
              <DialogContent
                className="draggable-title"
                sx={{
                  maxHeight: 130,
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                    borderRadius: "8px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#555",
                  },
                }}
              >
                <TableContainer
                  component={Paper}
                  elevation={0}
                  sx={{ boxShadow: "none", borderRadius: 0 }}
                >
                  <Table sx={{ borderCollapse: "collapse", borderTop: 0 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ borderBottom: "none" }}>
                          NAME
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }}>
                          JUMP To
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => (
                        <React.Fragment key={index}>
                          <TableRow
                            ref={index === rows.length - 1 ? newRowRef : null}
                          >
                            <TableCell sx={{ borderBottom: "none" }}>
                              <SuiInput
                                fullWidth
                                inputProps={{ maxLength: 100 }} // Use inputProps if supported by the custom component
                                value={row.value}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "value",
                                    e.target.value
                                  )
                                }
                                placeholder={`Option ${index + 1}`}
                              />
                              {/* {errors[index] && errors[index].value && (
                            <Box mt={1} color="red" fontSize="12px">
                              {errors[index].value}
                            </Box>
                          )} */}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "none",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                minWidth: "200px",
                              }}
                            >
                              <TextField
                                type="number"
                                size="small"
                                value={row.questionToHit}
                                onChange={(e) => {
                                  handleInputChange(
                                    index,
                                    "questionToHit",
                                    e.target.value
                                  );
                                }}
                                inputProps={{
                                  onWheel: (event) => event?.target?.blur(),
                                  sx: {
                                    width: "50px",
                                    height: "50px",
                                    textAlign: "center",
                                    maxWidth: "50px",
                                    border: "1px solid #E0E0E0",
                                    borderRadius: "4px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  },
                                  onKeyDown: (e) => {
                                    if (
                                      [
                                        "e",
                                        "E",
                                        "+",
                                        "-",
                                        ".",
                                        "ArrowUp",
                                        "ArrowDown",
                                      ].includes(e.key)
                                    ) {
                                      e.preventDefault();
                                    }
                                  },
                                }}
                              />
                              {/* {errors[index] && errors[index].questionToHit && (
                            <Box mt={1} color="red" fontSize="12px" textAlign="center">
                              {errors[index].questionToHit}
                            </Box>
                          )} */}
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                              >
                                {rows.length !== 1 && (
                                  <IconButton
                                    onClick={() => {
                                      handleRemoveRow(index);
                                      setErrors([]);
                                      setSaveError("");
                                    }}
                                    disabled={rows.length === 1}
                                    sx={{
                                      color: "red",
                                      "&:hover": {
                                        backgroundColor: "transparent",
                                      },
                                    }}
                                  >
                                    <RemoveIcon
                                      sx={{
                                        background: "#FF1733",
                                        color: "#FFFFFF",
                                        padding: "2px",
                                        borderRadius: "5px",
                                      }}
                                    />
                                  </IconButton>
                                )}
                                {index === rows.length - 1 &&
                                  !isAddDisabled && (
                                    <IconButton
                                      onClick={handleAddRow}
                                      sx={{
                                        color: "blue",
                                        "&:hover": {
                                          backgroundColor: "transparent",
                                        },
                                      }}
                                      disabled={isAddDisabled}
                                    >
                                      <AddIcon
                                        sx={{
                                          background: colors.primary.main,
                                          color: "#FFFFFF",
                                          padding: "2px",
                                          borderRadius: "5px",
                                        }}
                                      />
                                    </IconButton>
                                  )}
                              </Box>
                            </TableCell>
                          </TableRow>
                          {/* Adding an empty row for error display */}
                          {(errors[index]?.value ||
                            errors[index]?.questionToHit) && (
                            <TableRow>
                              <TableCell
                                colSpan={1}
                                sx={{ borderBottom: "none" }}
                              >
                                <Box color="red" fontSize="12px">
                                  {errors[index].value}
                                </Box>
                              </TableCell>
                              <TableCell
                                colSpan={1}
                                sx={{ borderBottom: "none" }}
                              >
                                <Box color="red" fontSize="12px">
                                  {errors[index]?.questionToHit}
                                </Box>
                              </TableCell>
                              {/* <TableCell></TableCell> */}
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContent>
              {saveError && (
                <Box mt={2} mb={2} sx={{ textAlign: "center" }}>
                  <h6
                    style={{
                      fontSize: ".9em",
                      color: "#FC3C3C",
                      fontWeight: 400,
                      transition: ".2s all",
                    }}
                  >
                    {saveError}
                  </h6>
                </Box>
              )}
              <DialogActions
                sx={{
                  justifyContent: "left",
                  marginLeft: "15px",
                  cursor: "pointer",
                }}
                className="draggable-title"
              >
                <SuiButton
                  onClick={() => {
                    handleClose(null);
                    setErrors([]);
                    setSaveError("");
                  }}
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ maxWidth: "94px !important" }}
                >
                  Cancel
                </SuiButton>
                <SuiButton
                  onClick={handleSave}
                  size="small"
                  variant="contained"
                  buttonColor="info"
                  sx={{ border: "1px solid #1264e2" }}
                >
                  Add
                </SuiButton>
              </DialogActions>
            </>
          )}
        </SuiBox>
      </Dialog>

      <Dialog
        disableScrollLock
        open={newLeadDialog}
        onClose={() => {
          handleClose(null);
          setErrors([]);
          setSaveError("");
        }}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        PaperComponent={DraggablePaperComponent}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            padding: "16px",
          },
        }}
      >
        <SuiBox>
          <IconButton
            className="close-btn"
            onClick={() => {
              setNewLeadDialog(false);
            }}
            color="light"
            sx={{ bgcolor: `${colors.text.main} !important` }}
          >
            <CloseRoundedIcon />
          </IconButton>
          <SuiBox className="draggable-title" style={{ cursor: "pointer" }}>
            <DragIndicatorIcon />
          </SuiBox>
          <DialogTitle
            className="draggable-title"
            fontWeight="700"
            style={{ cursor: "pointer" }}
          >
            {newTitle === "Selectable_Option"
              ? "Selectable Options"
              : newTitle === "Searchable_Dropdown"
              ? "Choose Answer(Drop Down)"
              : ""}
            {/* {dialogTitle} */}
            {/* Searchable Option */}
          </DialogTitle>
          <DialogContent
            className="draggable-title"
            sx={{
              maxHeight: 230,
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
                borderRadius: "8px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555",
              },
            }}
          >
            <TableContainer
              component={Paper}
              elevation={0}
              sx={{ boxShadow: "none", borderRadius: 0 }}
            >
              <Table sx={{ borderCollapse: "collapse", borderTop: 0 }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ borderBottom: "none" }}>NAME</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ldParamValue?.map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow
                        ref={
                          index === ldParamValue.length - 1 ? newRowRef : null
                        }
                      >
                        <TableCell sx={{ borderBottom: "none" }}>
                          <SuiInput
                            fullWidth
                            inputProps={{ maxLength: 100 }} // Use inputProps if supported by the custom component
                            value={row.value}
                            onChange={(e) => {
                              const newValue = e.target.value;

                              setLdParamValue((prev) => {
                                const updatedData = [...prev];

                                updatedData[index] = {
                                  ...updatedData[index],
                                  value: newValue,
                                };

                                return updatedData;
                              });
                              setErrorMessages((prevErrors) => {
                                const updatedErrors = [...prevErrors];
                                updatedErrors[index] =
                                  newValue.length <= 1
                                    ? "Value must be longer than 1 character"
                                    : "";
                                return updatedErrors;
                              });
                            }}
                            placeholder={`Option ${index + 1}`}
                          />
                          {errorMessages[index] && (
                            <div style={{ color: "red", fontSize: "0.875rem" }}>
                              {errorMessages[index]}
                            </div>
                          )}
                          {/* {errors[index] && errors[index].value && (
                              <Box mt={1} color="red" fontSize="12px">
                                {errors[index].value}
                              </Box>
                            )} */}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {ldParamValue.length !== 1 && (
                              <IconButton
                                onClick={() => {
                                  setLdParamValue((priv) => {
                                    const newRows = [...priv];
                                    newRows.splice(index, 1);
                                    return newRows;
                                  });
                                }}
                                disabled={ldParamValue.length === 1}
                                sx={{
                                  color: "red",
                                  "&:hover": {
                                    backgroundColor: "transparent",
                                  },
                                }}
                              >
                                <RemoveIcon
                                  sx={{
                                    background: "#FF1733",
                                    color: "#FFFFFF",
                                    padding: "2px",
                                    borderRadius: "5px",
                                  }}
                                />
                              </IconButton>
                            )}
                            {index === ldParamValue.length - 1 && (!isLeadAddDisabled) && (
                              <IconButton
                                onClick={() => {
                                  setLdParamValue((priv) => [
                                    ...priv,
                                    {
                                      id: index + 1,
                                      value: "",
                                    },
                                  ]);
                                }}
                                sx={{
                                  color: "blue",
                                  "&:hover": {
                                    backgroundColor: "transparent",
                                  },
                                }}
                                disabled={isLeadAddDisabled}
                              >
                                <AddIcon
                                  sx={{
                                    background: colors.primary.main,
                                    color: "#FFFFFF",
                                    padding: "2px",
                                    borderRadius: "5px",
                                  }}
                                />
                              </IconButton>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                      {/* Adding an empty row for error display */}
                      {(errors[index]?.value ||
                        errors[index]?.questionToHit) && (
                        <TableRow>
                          <TableCell colSpan={1} sx={{ borderBottom: "none" }}>
                            <Box color="red" fontSize="12px">
                              {errors[index].value}
                            </Box>
                          </TableCell>
                          <TableCell colSpan={1} sx={{ borderBottom: "none" }}>
                            <Box color="red" fontSize="12px">
                              {errors[index]?.questionToHit}
                            </Box>
                          </TableCell>
                          {/* <TableCell></TableCell> */}
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          {saveError && (
            <Box mt={2} mb={2} sx={{ textAlign: "center" }}>
              <h6
                style={{
                  fontSize: ".9em",
                  color: "#FC3C3C",
                  fontWeight: 400,
                  transition: ".2s all",
                }}
              >
                {saveError}
              </h6>
            </Box>
          )}
          <DialogActions
            sx={{
              justifyContent: "left",
              marginLeft: "15px",
              cursor: "pointer",
            }}
            className="draggable-title"
          >
            <SuiButton
              onClick={() => {
                setNewLeadDialog(false);
              }}
              size="small"
              variant="outlined"
              buttonColor="dark"
              sx={{ maxWidth: "94px !important" }}
            >
              Cancel
            </SuiButton>
            <SuiButton
            disabled={ldParamValue.some((row) => row.value.length <= 1)}
              onClick={() => {
                handleSaveLeadFormValue();
                setNewLeadDialog(false);
                // if (newOpenDialog) {
                //   handleOpenDialog(0, "Lead_Collection_Form");
                // }
              }}
              size="small"
              variant="contained"
              buttonColor="info"
              // sx={{ border: "1px solid #1264e2" }}
            >
              Add
            </SuiButton>
          </DialogActions>
        </SuiBox>
      </Dialog>
    </div>
  );
};

export default ResponsiveDialogWithTable;
